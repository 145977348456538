.ac-multiselect-container {
  .chip {
    i {
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
  }
}

#ac-user-skill-multiselect {
  > div {
    padding: 0;
    min-height: auto;
    .chip{

        i{
            font-size: 2.4rem;
            line-height: 1rem;
            &:before{
                width: 1.4rem;
                line-height: 1.4rem;
                height: 1.4rem;
            }
        }
    }
  }

}
