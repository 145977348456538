.notes-box {
  .notes-title {
    padding: 0px 5px;
    border-bottom: 1px solid #4378ae;
    margin-bottom: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .txt-notes {
    resize: none;
  }
}

.activity-container {
  .activity-title {
    padding: 0px 5px;
    border-bottom: 1px solid #4378ae;
    margin-bottom: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .al-row {
    border-bottom: 1px solid #c7c7c7;
    padding: 5px 22px;
    position: relative;
    .al-activity {
      // .badge {
      //   vertical-align: middle;
      //   font-size: 1.2rem;
      //   display: inline-flex;
      //   align-items: center;
      //   justify-content: center;
      //   margin-left: 5px;
      //   .al-activity-time {
      //     vertical-align: middle;
      //     font-size: 1.2rem;
      //     display: inline-flex;
      //     align-items: center;
      //     justify-content: center;
      //     svg {
      //       margin-right: 5px;
      //     }
      //   }
      // }

      .al-activity-time {
        vertical-align: middle;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .al-row:last-child {
    border-bottom: 0;
  }

  .al-check {
    position: absolute;
    left: 5px;
    top: 9px;
    cursor: pointer;
  }

  .al-edit {
    position: absolute;
    right: 5px;
    top: 9px;
    cursor: pointer;
  }

  .add-default-activities {
    background: #e4ecfb;
    position: relative;
    padding: 24px 20px;
    outline: 2px dashed #acb3be;
    outline-offset: 0px;
    margin: 5px;
    color: rgba(33, 37, 41, 0.7);
    span {
      color: #0786d2;
      cursor: pointer;
    }

    span:hover {
      text-decoration: underline;
    }
  }
}
