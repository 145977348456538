@import 'variables';

.ac-light {
  .navbar-custom {
    background: $white;
    border-bottom: 1px solid $ac-light-border-color;
    box-shadow: 0px 0px 2px 0px $ac-light-box-shadow-color;
  }

  .ac-sidebar {
    background: $white;
    border-right: 1px solid $ac-light-border-color;
    box-shadow: 0px 0px 2px 0px $ac-light-box-shadow-color;
  }

  .ac-sidebar-toggle {
    background: $white;
    border: 3px solid $ac-dark-blue;
    border: 1px solid $ac-light-border-color;
  }

  .ac-background-root {
    background: rgba(228, 236, 251, 0.4);
  }

  .ac-power-by-block {
    color: $ac-light-color;
  }

  .ac-sb-nav-link {
    color: $ac-light-color;
  }

  .navbar-custom .navbar-nav .nav-link {
    color: $ac-light-color;
  }

  .ac-sb-nav-link path {
    stroke: $ac-light-color !important;
  }

  .chart-number-box {
    border: 1px solid $ac-light-border-color;
  }

  .my-opportunities-title {
    color: $ac-light-color;
  }
  .chart-number-box h4 {
    color: $ac-light-color;
  }
  .chart-number-row {
    color: $ac-light-color;
  }
}
