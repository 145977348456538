/*
* Imported at app.scss
*/

.policy-view-page {
  height: 100%;

  .policy-view-filter {
    height: 40px;
    padding: 6px 0px;
  }
  .policy-view-grid {
    height: calc(100% - 40px); // Height of policy-view-filter
  }
}
