html,
body,
#root {
  height: 100%;
}
html {
  font-size: 10px;
}
body {
  font-family: 'Roboto';

  font-size: 1.4rem;

  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ac-pr {
  position: relative;
}
.ac-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.ac-form-box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ac-label-tooltip {
  display: inline-block;
  padding-right: 10px;
}

@media (min-width: 576px) {
  .ac-masonry-2 {
    -moz-column-count: 2;
    column-count: 2;
  }
}
.ac-masonry-content {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

/*
 *  Scrollbar css starts
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e4ecfb;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background-color: #002e67; */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #002e67;
}

/*
 *  Scrollbar css ends
 */

/*
 *  AC Table css starts
 */
.ac-table {
  text-align: center;

  &.ac-table-fix-head {
    overflow-y: auto;
    thead th {
      position: sticky;
      top: 0;

      box-shadow: inset 0px 0px #4378ae, 0 1px 0px 1px #4378ae;
      border: 1px solid #4378ae;
      border-bottom: 2px solid #4378ae;
    }
  }

  td {
    border: 1px solid #4378ae;
    vertical-align: middle;

    border: 0px solid #4378ae;
    vertical-align: middle;
    border-top: 1px solid #dde2eb;
  }
  thead {
    th {
      background: rgba(98, 185, 248, 0.48);
      background: #b4defc;
      border: 1px solid #4378ae;
      border-bottom: 2px solid #4378ae;
      vertical-align: middle;
    }
  }
  tbody {
    tr {
      &.selected {
        /* border: 2px solid #0786d2; */

        background-color: #0786d2;
        color: white;

        td {
          border-color: white;
          border-color: #555;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(98, 185, 248, 0.19);
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.ac-table-sortable {
  .ac-t-header-con {
    display: flex;
    align-items: center;
    justify-content: center;
    .ac-t-header {
      flex: 1;
    }
  }
}
// .ac-table.table-bordered td {
//   border: 1px solid #4378ae;
//   vertical-align: middle;
// }

// .ac-table.table-striped tbody tr:nth-of-type(odd) {
//   background-color: rgba(98, 185, 248, 0.19);
// }

// .ac-table.table-striped tbody tr.selected {
//   /* border: 2px solid #0786d2; */

//   background-color: #0786d2;
//   color: white;
// }
// .ac-table.table-striped tbody tr.selected td {
//   border-color: white;
//   border-color: #555;
// }

// .ac-table-fix-head {
//   overflow-y: auto;
// }
// .ac-table-fix-head thead th {
//   position: sticky;
//   top: 0;
// }

// /* Borders (if you need them) */
// .ac-table-fix-head th {
//   box-shadow: inset 0px 0px #4378ae, 0 1px 0px 1px #4378ae;
//   border: 1px solid #4378ae;
//   border-bottom: 2px solid #4378ae;
// }

/*
 *  AC Table css ends
 */

/*
 *  AC Split Box css starts
 */

.ac-split-box {
  display: flex;
  flex-direction: row;
  height: 100%;

  .ac-split {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .gutter-horizontal {
    cursor: col-resize;
    position: relative;
  }
  .gutter-horizontal:hover {
    background: rgba(98, 185, 248, 0.48);
  }

  .gutter-horizontal::before {
    content: '';
    height: 40px;
    width: 5px;
    background: #0786d2;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 3px;
    margin-top: -20px;
  }
}

.ac-split-box-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ac-split {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .gutter-vertical {
    cursor: row-resize;
    position: relative;
  }
  .gutter-vertical:hover {
    background: rgba(98, 185, 248, 0.48);
  }
  .gutter-vertical::before {
    content: '';
    width: 40px;
    height: 5px;
    background: #0786d2;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 3px;
    margin-left: -20px;
  }
}

/*
 *  AC Split Box css ends
 */

/*
 *  AC Date css starts
 */
.react-datepicker {
  font-size: 1.4rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3rem;
  line-height: 2rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.4rem;
}
.react-datepicker__navigation {
  border: 1rem solid transparent;
  height: 15px;
  width: 15px;
}
.react-datepicker__navigation--previous {
  border-right-color: #bbb;
}
.react-datepicker__navigation--next {
  border-left-color: #bbb;
}
.react-datepicker__tab-loop {
  height: 0;
}
.react-datepicker-popper {
  position: absolute;
  z-index: 10;
}
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  width: 100px;
}

.ac-month-picker {
  .react-datepicker__header {
    padding-bottom: 8px;
    width: 270px;
  }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    width: 8rem;
  }
  .react-datepicker-wrapper {
    width: 100px;
    .form-control-sm {
      width: 100%;
    }
  }
}
/*
 *  AC Date css ends
 */

/*
 *  AC Autocomplete css starts
 */
.ac-autocomplete-items {
  font-size: 1.4rem;
  background: #fff;
  padding: 4px 6px;
  cursor: pointer;
}
.ac-autocomplete-items:hover {
  background: #0786d2;
  color: white;
}

.ac-autocomplete-items.active {
  background: #0786d2;
  color: white;
}

/*
 *  AC Autocomplete css ends
 */

.ac-textarea {
  resize: none;
}

.gi-fill-fix {
  path {
    fill: currentcolor;
  }

  polygon {
    fill: currentcolor;
  }
}

/*
* ALL Imports starts
*/
@import './ac-tabs.scss';
@import '../../opportunities/css/opportunity.scss';
@import '../../opportunities-new/css/opportunity.scss';
@import '../../opportunities/components/dependents/css/dependents.scss';
@import '../../opportunities/components/activity/css/activity.scss';
@import '../../opportunities/components/documents/css/document.scss';
@import '../../opportunities/components/history/css/history.scss';
@import '../../policies/css/policy.scss';
@import '../../commissions-new/css/commission-common.scss';
@import '../../policy-view/css/policiespage.scss'; /*
 *  ALL Imports ends
 */

.customer-notes-popup-custom-width {
  width: 80%;
  max-width: 80%;

  .customer-notes-tab-container {
    .tab-content {
      max-height: 400px;
      overflow: auto;
    }
  }

  .customer-notes-table {
    th:nth-child(1) {
      width: 180px;
    }
    th:nth-child(2) {
      width: 300px;
    }
  }
}
