.dependent-table{

}
.dependent-header:nth-child(1){
    width: 100px;
}
.dependent-header:nth-child(2){
    width: 100px;
}
.dependent-header:nth-child(3){
    width: 100px;
}
.dependent-header:nth-child(4){
    width: 100px;
}
.dependent-header:nth-child(5){
    width: 90px;
}
.dependent-header:nth-child(6){
    width: 100px;
}

.dependent-actions span{
    margin: 0 5px;
    height: 24px;
    width: 24px;
    font-size: 1.6rem;
    line-height: 2rem;
}
.dependent-actions span svg{
    /* height: 14px;
    width: 14px; */
}

.dependent-popup-custom-width{
    /* width: 90%;
    max-width: none !important; */
}