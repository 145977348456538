/*
* Imported at app.scss
*/

.my-commissions-page {
  height: 100%;

  .my-commissions-filter {
      height: 40px;
      padding: 6px 0px;
  }
  .my-commissions-grid {
    height: calc(100% - 40px); // Height of my-commissions-filter
  }
}


.agent-commisions-page{
  height: 100%;

  .agent-commisions-filter {
      height: 40px;
      padding: 6px 0px;
  }
  .agent-commisions-grid {
    height: calc(100% - 40px); // Height of my-commissions-filter
  }
}