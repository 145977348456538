.history-table{

}
.history-header:nth-child(1){
    width: 160px;
}
.history-header:nth-child(2){
    width: 180px;
}
.history-header:nth-child(3){
    width: 120px;
}
