.ac-tabs-container {
  border: 1px solid #c7c7c7;
  padding-top: 10px;
  height: 100%;
  border-top: 0;

  .o-left-tabs {
    padding-left: 18px;
    flex: 1;
  }

  .o-right-tabs {
    padding-right: 5px;
  }

  .nav-tabs .nav-link {
    padding: 6px 15px 1px 15px;
    line-height: 18px;
    height: 32px;
    color: #0786d2;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: #c7c7c7 #c7c7c7 #fff;
    color: #495057;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
  }

  .nav-tabs .nav-link.active:hover {
    border-color: #c7c7c7 #c7c7c7 #fff;
  }

  .tab-content {
    height: calc(100% - 32px);
    .tab-pane {
      height: 100%;
      overflow: auto;
    }
  }
}
