.login-page {
  background: url('../bg_loginpage.jpg') no-repeat center center fixed;
  height: 100%;
  width: 100%;
  background-size: 100%;
  background-size: cover;

  .login-form {
    padding-top: 300px;

    .login-title {
      border-bottom: 2px solid #5d91d2;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      font-size: 2.8rem;
      color: #5d91d2;
    }

    .forgot-pwd-link {
      float: right;
    }
  }
}
