.p-table {
  min-width: 710px;
  min-width: 1800px;
}
/* .p-header:nth-child(1){
    width: 155px;
}
.p-header:nth-child(2){
    width: 125px;
}
.p-header:nth-child(3){
    width: 70px;
}
.p-header:nth-child(4){
    width: 90px;
}
.p-header:nth-child(5){
    width: 120px;
} */
.p-header:nth-child(6) {
  /* width: 100px; */
}

.policy-actions span {
  margin: 0 5px;
  height: 24px;
  width: 24px;
  font-size: 1.6rem;
  line-height: 2rem;
}
.policy-actions span svg {
  /* height: 14px;
    width: 14px; */
}

.policy-popup {
  width: 60%;
  max-width: 60%;
}
