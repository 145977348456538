.clients-new-page {
  .ac-split-box {
    &.property-pane-hidden {
      .gutter-horizontal {
        display: none;
      }

      .property-pane-hidden-span {
        display: none;
      }
    }
  }
  .ac-split-box-vertical {
    &.detail-view-split {
      &.detail-pane-hidden {
        & > .ac-split > .gutter-vertical {
          display: none;
        }
        .detail-view-hidden-span {
          display: none;
        }
      }

      .customer-lead-view-split {
        &.lead-pane-hidden {
          .gutter-vertical {
            display: none;
          }
          .hidden-span {
            display: none;
          }
        }
        &.customer-pane-hidden {
          .gutter-vertical {
            display: none;
          }
          .hidden-span {
            display: none;
          }
        }
      }
    }
  }

  .pane-header {
    background-color: #eeeeee;
    border: 1px solid #c7c7c7;
    padding: 3px 8px;
    flex-shrink: 0;
    strong {
    }
    .toggle-btn {
      cursor: pointer;
    }
  }

  .bs-btns {
    font-size: 1.4rem;
    .btn {
      font-size: 1.4rem;
    }
  }

  .client-search-box {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}
