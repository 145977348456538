.ac-phone.react-tel-input{
    flex: 1;
    .custom-phone-input{
        font-size: 1.4rem;
        height: calc(1.5em + .5rem + 2px);
        padding: .25rem .5rem;
        padding-left: 48px;
        line-height: 1.5;
        border-radius: .2rem;
        width: 100%;
    }
}