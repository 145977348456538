@font-face {
  font-family: '3';
  src: url('../font/1-regular-webfont.woff');
}
.support-body {
  font-family: '3';
  height: 100%;
  background-image: url('img/9.png');
  background-repeat: repeat;

  .options {
    position: absolute;
    left: 20px;
    top: 20px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  .options-1 img {
    width: 30px;
    background-image: url('img/9.png');
  }
  .options-1 {
    position: relative;
    display: inline-block;
    width: 40px;
    text-align: center;
    cursor: pointer;
  }
  .options-text {
    position: absolute;
    width: 100%;
    text-align: center;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    top: 35px;
    /* z-index: -1; */
    color: #444;
    font-size: 12px;
  }
  .options-1:hover .options-text {
    /* top: 35px; */
  }
  .options-1 a {
    text-decoration: none;
    color: #444;
  }

  .options-1 .go-back {
    color: #444;
  }

  .title {
    color: #444;
    text-align: center;
    font-size: 20pt;
    margin: 18px 0px;
  }
  .error-no {
    display: inline-block;
    font-size: 60pt;
    line-height: 0px;
    position: relative;
    top: 15px;

    color: black;
    padding: 8px;
  }
}
