.ac-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #004c9e;
  overflow: hidden;
}
.ac-loader {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ac-loader > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.ac-loader .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.ac-loader .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.ac-loader .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.ac-loader .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
