.o-view-more {
  position: relative;
  text-align: center;
  height: 30px;
  line-height: 30px;

  .o-view-more-link {
    cursor: pointer;
    color: #0786d2;
  }
  .o-view-more-link:hover {
    text-decoration: underline;
  }
}

.o-tab-container-parent {
  height: 100%;
  overflow: auto;
  &.hide-details {
    overflow-y: hidden;
  }

  .o-tab-container {
    height: 100%;
    min-width: 840px;
    border: 1px solid #c7c7c7;
    padding-top: 5px;

    .o-left-tabs {
      padding-left: 18px;
      flex: 1;
    }

    .o-right-tabs {
      padding-right: 5px;
    }

    .nav-tabs .nav-link {
      padding: 6px 15px 1px 15px;
      line-height: 18px;
      height: 32px;
      color: #0786d2;
      &.pending {
        border-bottom: 3px solid #ffc107;
      }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      border-color: #c7c7c7 #c7c7c7 #fff;
      color: #495057;
      &.pending {
        border-bottom: 3px solid #ffc107;
      }
    }

    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-color: #c7c7c7 #c7c7c7 #fff;
      &.pending {
        border-bottom: 3px solid #ffc107;
      }
    }

    .nav-tabs .nav-link.active:hover {
      border-color: #c7c7c7 #c7c7c7 #fff;
      &.pending {
        border-bottom: 3px solid #ffc107;
      }
    }

    .tab-content {
      height: calc(100% - 32px);
      overflow: auto;
    }
  }
}

.oi-row-cont {
  column-count: 2;
  padding-top: 10px;

  .oi-row {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    flex-direction: column;

    .oi-row-notes {
      padding: 0px 8px;
      margin-bottom: 10px;
      width: 100%;
      .oi-row-notes-lbl {
        font-weight: bold;
      }
    }
  }
  .oi-lbl.large {
    display: block;
  }
  .oi-lbl.compact {
    display: none;
  }
  .oi-val {
    .form-check {
      text-align: left;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 0;

      .form-check-grp {
        display: inline-flex;
        align-items: center;
        margin-right: 15px;
      }

      .form-check-input {
        position: inherit;
        margin-top: 0;
        margin-left: 0;
      }
      .form-check-lbl {
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
      }
    }
    .react-datepicker-wrapper {
      width: calc(100% - 35px);
    }
    .input-group {
      button,
      input {
        flex: 1;
      }
    }
  }
}

.compact-o-info {
  .oi-row-cont {
    column-count: auto;

    .oi-row {
      flex-direction: row;
    }
    .oi-lbl {
      flex-basis: 40%;
      text-align: right;
    }

    .large {
      display: none;
    }
    .compact {
      display: block;
    }
    .oi-val {
      flex-basis: 60%;
      .react-datepicker-wrapper {
        width: calc(100% - 35px);
      }
    }
  }
}

.add-opportunity-popup-custom-width {
  width: 60%;
  max-width: 60%;
}

.opportunities-page {
  .opportunity-grid {
    .opportunity-completed {
      .ag-cell {
        text-decoration: line-through;
      }
    }
  }
}

@-webkit-keyframes h-move {
  0% {
    left: -5px;
  }
  100% {
    left: calc(100% + 5px);
  }
}
@keyframes h-move {
  0% {
    left: -5px;
  }
  100% {
    left: calc(100% + 5px);
  }
}

.ag-theme-alpine {
  .ac-grid-row-loading {
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 3px;
      width: 60px;
      background: #0786d2;
      transition: all 5s linear;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: h-move;
      animation-name: h-move;
    }

    &::before {
      top: 0px;
      left: -5px;
    }
    &::after {
      bottom: 0px;
      left: -5px;
    }
  }
}

.cross-sale-box {
  background: #e4ecfb;
  position: relative;
  padding: 24px 20px;
  outline: 2px dashed #acb3be;
  outline-offset: 0px;
  margin: 5px;
  color: rgba(33, 37, 41, 0.7);
  span {
    color: #0786d2;
    cursor: pointer;
  }

  span:hover {
    text-decoration: underline;
  }
}

.assign-to-me {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.frm-grp-checkbox {
  .form-check {
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0;

    .form-check-grp {
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
    }

    .form-check-input {
      position: inherit;
      margin-top: 0;
      margin-left: 0;
    }
    .form-check-lbl {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;
    }
  }
}
// client-commission-table starts
.client-commission-table {
  .cc-header {
  }
}

// client-commission-table ends

.constent-options-radio {
  .form-check-grp {
    cursor: pointer;
    .form-check-input {
      cursor: pointer;
    }
  }
}
